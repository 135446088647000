import React from 'react';
import PropTypes from 'prop-types';

import { PageSpacing, LeftHalf, RightHalf, LogoContainer, TopLeftPositioner } from './styles';
import Navbar from '../Navbar';
import { CompleteLogo, MobileLogo } from '../../svg-icons';
import { NAVBAR_LINKS, NAVBAR_LINK_LABELS_BY_PATH } from '../../utils/constants';

const TwoColumnPage = ({ children, currentPathName }) => (
  <PageSpacing className="container-fluid">
    <LeftHalf className="col-sm-6 hidden-xs">
      <TopLeftPositioner>
        <LogoContainer to="/">
          <CompleteLogo />
        </LogoContainer>
      </TopLeftPositioner>
    </LeftHalf>
    <RightHalf className="col-sm-6 hidden-xs">
      <Navbar
        links={NAVBAR_LINKS}
        isCurrentLink={(link) => link === currentPathName}
        getLinkLabel={(linkPath) => NAVBAR_LINK_LABELS_BY_PATH[linkPath]}
      />
      {children}
    </RightHalf>
    <RightHalf className="visible-xs">
      <Navbar
        links={NAVBAR_LINKS}
        isCurrentLink={(link) => link === currentPathName}
        getLinkLabel={(linkPath) => NAVBAR_LINK_LABELS_BY_PATH[linkPath]}
        renderLogo={() => (
          <LogoContainer to="/">
            <MobileLogo />
          </LogoContainer>
        )}
      />
      {children}
    </RightHalf>
  </PageSpacing>
);

TwoColumnPage.propTypes = {
  children: PropTypes.node.isRequired,
  currentPathName: PropTypes.string.isRequired,
};

export default TwoColumnPage;
