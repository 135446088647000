import React from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import './styles/bootstrap/bootstrap.min.css';
import './App.css';
import Splash from './containers/Splash';
import About from './containers/About';

const PAGE_TRANSITION_DURATION = 600;
const SlideAnimationContext = styled.div`
  .slide-enter {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 100vw;
    width: 100%;
    background-color: white;
  }

  .slide-enter.slide-enter-active {
    left: 0;
    transition: left ${({ duration }) => duration}ms ease-in-out;
  }

  .slide-exit {
    position: absolute;
    left: 0;
    z-index: 0;
    width: 100%;
  }

  .slide-exit.slide-exit-active {
    left: 0;
  }
`;

const isAnimatedRoute = (route) => route === '/' || route === '/about';

const App = ({ location }) => (
  <SlideAnimationContext duration={PAGE_TRANSITION_DURATION}>
    {isAnimatedRoute(location.pathname) && (
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="slide" timeout={PAGE_TRANSITION_DURATION}>
          <Switch location={location}>
            <Route exact path="/" component={Splash} />
            <Route path="/about" component={About} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    )}
  </SlideAnimationContext>
);

App.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string, key: PropTypes.string }).isRequired,
};

export default withRouter(App);
