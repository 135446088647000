import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import HoverToReveal from '../../components/HoverToReveal';
import { ArrowWithBackground, Enable, Empower, Certify } from '../../svg-icons';
import { LogoContainer, Layout, AdvanceButton } from './styles';
import { Colors, Animations } from '../../themes';

const TIMEOUT_BEFORE_ANIMATION = 1000;

class Splash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstRowBackgroundColor: Colors.brightGreen,
      secondRowBackgroundColor: Colors.brightGreen,
      thirdRowBackgroundColor: Colors.brightGreen,
    };

    this.transitionToFirstContentPage = this.transitionToFirstContentPage.bind(this);
  }

  componentDidMount() {
    const slideToLeftDuration = Animations.durations.splashScreenMobileSlide / 2;
    const previousSlideAnimationDuration = slideToLeftDuration;

    window.setTimeout(() => {
      this.setState({ firstRowBackgroundColor: Colors.background });
    }, slideToLeftDuration + TIMEOUT_BEFORE_ANIMATION);
    window.setTimeout(() => {
      this.setState({ secondRowBackgroundColor: Colors.background });
    }, previousSlideAnimationDuration + slideToLeftDuration + TIMEOUT_BEFORE_ANIMATION);
    window.setTimeout(() => {
      this.setState({ thirdRowBackgroundColor: Colors.background });
    }, 2 * previousSlideAnimationDuration + slideToLeftDuration + TIMEOUT_BEFORE_ANIMATION);
    window.setTimeout(() => {
      this.advanceButtonRef.scrollIntoView({ behavior: 'smooth' });
    }, 3 * previousSlideAnimationDuration + TIMEOUT_BEFORE_ANIMATION);
  }

  transitionToFirstContentPage() {
    const { history } = this.props;

    history.push('/about');
  }

  render() {
    const { firstRowBackgroundColor, secondRowBackgroundColor, thirdRowBackgroundColor } = this.state;
    return (
      <Layout className="container-fluid">
        <LogoContainer mobileBackgroundColor={firstRowBackgroundColor} borderedAfter className="col-sm-3">
          <HoverToReveal
            animateOnLoadOrder={0}
            timeoutUntilAnimateOnLoad={TIMEOUT_BEFORE_ANIMATION}
            hiddenText="ENABLE"
            hiddenSlogan="wir befähigen"
          >
            <Enable />
          </HoverToReveal>
        </LogoContainer>
        <LogoContainer mobileBackgroundColor={secondRowBackgroundColor} borderedBefore borderedAfter className="col-sm-3">
          <HoverToReveal
            animateOnLoadOrder={1}
            timeoutUntilAnimateOnLoad={TIMEOUT_BEFORE_ANIMATION}
            hiddenText="EMPOWER"
            hiddenSlogan="wir ermöglichen"
          >
            <Empower />
          </HoverToReveal>
        </LogoContainer>
        <LogoContainer mobileBackgroundColor={thirdRowBackgroundColor} borderedBefore borderedAfterInMobile className="col-sm-6">
          <HoverToReveal
            animateOnLoadOrder={2}
            timeoutUntilAnimateOnLoad={TIMEOUT_BEFORE_ANIMATION}
            hiddenText="CERTIFY"
            hiddenSlogan="wir bestätigen"
          >
            <Certify />
          </HoverToReveal>
        </LogoContainer>
        <div
          style={{ width: '100%' }}
          ref={(el) => {
            this.advanceButtonRef = el;
          }}
        >
          <AdvanceButton onClick={this.transitionToFirstContentPage}>
            <ArrowWithBackground />
          </AdvanceButton>
        </div>
      </Layout>
    );
  }
}

Splash.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
};

export default withRouter(Splash);
