import React from 'react';
import PropTypes from 'prop-types';

import { NavbarContainer, TwoPartNavbar, NavbarGroup, LinkContainer, NavbarLink, DeactivatedNavbarLink } from './styles';

const Navbar = ({ renderLogo, links, isCurrentLink, getLinkLabel }) => {
  const renderedLinks = links.map((link, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <LinkContainer key={`${link}-${index}`} first={index === 0} last={index === links.length - 1} selected={isCurrentLink(link)}>
      {isCurrentLink(link) ? (
        <DeactivatedNavbarLink>{getLinkLabel(link)}</DeactivatedNavbarLink>
      ) : (
        <NavbarLink to={link}>{getLinkLabel(link)}</NavbarLink>
      )}
    </LinkContainer>
  ));

  return (
    <NavbarContainer>
      {renderLogo ? (
        <TwoPartNavbar>
          <NavbarGroup>{renderLogo()}</NavbarGroup>
          <NavbarGroup>{renderedLinks}</NavbarGroup>
        </TwoPartNavbar>
      ) : (
        renderedLinks
      )}
    </NavbarContainer>
  );
};

Navbar.defaultProps = {
  links: [],
  isCurrentLink: (link) => false,
  getLinkLabel: (link) => `${link}`,
  renderLogo: null,
};

Navbar.propTypes = {
  renderLogo: PropTypes.func,
  links: PropTypes.arrayOf(PropTypes.string),
  isCurrentLink: PropTypes.func,
  getLinkLabel: PropTypes.func,
};

export default Navbar;
