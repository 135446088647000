import React from 'react';
import PropTypes from 'prop-types';
import { animationDuration, Container, HidableText, HidableSlogan, CoverImageWrapper } from './styles';

const HoverToReveal = ({ hiddenText, hiddenSlogan, animateOnLoadOrder, timeoutUntilAnimateOnLoad, children }) => {
  const animationDelay =
    animateOnLoadOrder >= 0
      ? animateOnLoadOrder * (animationDuration / 2) + // start halfway the previous animation
        timeoutUntilAnimateOnLoad // initial delay until starting the chain of animations
      : undefined;
  return (
    <Container>
      <HidableText>{hiddenText}</HidableText>
      <HidableSlogan>{hiddenSlogan}</HidableSlogan>
      <CoverImageWrapper animationDelay={animationDelay}>{children}</CoverImageWrapper>
    </Container>
  );
};

HoverToReveal.propTypes = {
  timeoutUntilAnimateOnLoad: 0,
};

HoverToReveal.propTypes = {
  hiddenText: PropTypes.string.isRequired,
  hiddenSlogan: PropTypes.string.isRequired,
  animateOnLoadOrder: PropTypes.number.isRequired,
  timeoutUntilAnimateOnLoad: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default HoverToReveal;
