import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Metrics } from '../../themes/index';

export const PageSpacing = styled.div`
  margin: 0;
  padding-top: ${Metrics.margins.defaultMarginToEdges}px;
  padding-bottom: ${Metrics.margins.defaultMarginToEdges}px;
  padding-left: ${Metrics.margins.defaultMarginToEdges}px;
  padding-right: ${Metrics.margins.defaultMarginToEdges}px;
  @media screen and (max-width: ${Metrics.grid.phoneMax}px) {
    padding: 4vh 4vw;
  }
`;

export const ScreenHalf = styled.div`
  @media screen and (max-width: ${Metrics.grid.phoneMax}px) {
    padding: 0;
  }
`;

export const LeftHalf = styled(ScreenHalf)`
  position: relative;
`;

export const RightHalf = styled(ScreenHalf)``;

export const LogoContainer = styled(Link)`
  height: ${Metrics.components.navbarReservedSpace}px;
  svg {
    height: ${Metrics.components.navbarReservedSpace}px;
  }
  @media screen and (max-width: ${Metrics.grid.phoneMax}px) {
    height: ${Metrics.components.navbarLinkHeightMobile}px;
    svg {
      height: ${Metrics.components.navbarLinkHeightMobile}px;
    }
  }
`;

export const TopLeftPositioner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
`;
