const baseBreakpoints = {
  giant: 1400,
  desktop: 992,
  tablet: 768,
};

const grid = {
  gutter: 12,
  giant: baseBreakpoints.giant,
  giantMin: baseBreakpoints.giant,
  desktopMax: baseBreakpoints.giant - 1,
  desktop: baseBreakpoints.desktop,
  desktopMin: baseBreakpoints.desktop,
  tabletMax: baseBreakpoints.desktop - 1,
  tablet: baseBreakpoints.tablet,
  tabletMin: baseBreakpoints.tablet,
  phoneMax: baseBreakpoints.tablet - 1,
};

const margins = {
  defaultMarginToEdges: 30,
};

const baseMetrics = {
  navbarLinkHeight: 25,
};

const components = {
  navbarReservedSpace: baseMetrics.navbarLinkHeight * 3,
  navbarLinkHeight: baseMetrics.navbarLinkHeight,
  navbarLinkHeightMobile: baseMetrics.navbarLinkHeight * 0.8,
  textContentMaxWidth: 600,
};

export default { grid, margins, components };
