const baseSizes = {
  base: 1,
  headline: 1.35,
};

const components = {
  navbarLink: baseSizes.headline,
  textContentHeadline: baseSizes.headline,
};

components.textContentHeadline = components.navbarLink;

const styles = {};

export default { baseSizes, components, styles };
