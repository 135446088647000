import styled from 'styled-components';
import { Colors, Metrics, Animations } from '../../themes';
import '../../styles/animations.css';

const smallerThanBreakPoint = Metrics.grid.phoneMax;
const greaterThanBreakPoint = Metrics.grid.tabletMin;

export const animationDuration = Animations.durations.splashScreenMobileSlide;

export const HidableText = styled.p`
  height: auto;
  width: 100%;
  margin: 0 auto;
  font-size: 1.2rem;

  @media screen and (max-width: ${smallerThanBreakPoint}px) {
    display: none;
  }
`;

export const HidableSlogan = styled.p`
  height: auto;
  width: 100%;
  margin: 0 auto;
  font-size: 2rem;
  margin-top: 50px;

  @media screen and (max-width: ${smallerThanBreakPoint}px) {
    display: none;
  }
`;

export const CoverImageWrapper = styled.div`
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  height: auto;
  background-color: ${Colors.background};
  text-align: left;
  transition: top 0.8s ease-in-out;

  svg {
    width: 100%;
    transition: transform 0.8s ease-in-out;
  }

  @media screen and (max-width: ${smallerThanBreakPoint}px) {
    position: relative;
    height: 100%;
    width: auto;
    background-color: transparent;
    svg {
      height: 100%;
      width: auto;
      ${({ animationDelay }) =>
        animationDelay >= 0
          ? `animation:
					slide-self-left ${animationDuration}ms
					ease-in-out
					${animationDelay}ms`
          : ``};
    }
    ${({ animationDelay }) =>
      animationDelay >= 0
        ? `animation:
					slide-right ${animationDuration}ms
					ease-in-out
					${animationDelay}ms`
        : ``};
  }
`;

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;

  @media screen and (max-width: ${smallerThanBreakPoint}px) {
    text-align: left;
  }

  @media screen and (min-width: ${greaterThanBreakPoint}px) {
    &:hover ${CoverImageWrapper} {
      top: 100%;

      svg {
        transform: translate(0, -100%);
      }
    }
  }
`;
