import styled from 'styled-components';
import { Colors, Metrics } from '../../themes';
import '../../styles/animations.css';

const breakPointMax = Metrics.grid.phoneMax;
const borderThickness = 2;
const advanceButtonHeight = 50;

export const Layout = styled.div`
  position: relative;
  overflow-y: hidden;
  margin-top: ${Metrics.margins.defaultMarginToEdges}px;
  margin-bottom: ${Metrics.margins.defaultMarginToEdges}px;
  padding: 0;

  @media screen and (max-width: ${breakPointMax}px) {
    height: 100vh;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: ${Colors.brightGreen};
  }
`;

export const LogoContainer = styled.div`
  height: calc(100vh - ${2 * Metrics.margins.defaultMarginToEdges}px);
  padding-left: ${Metrics.margins.defaultMarginToEdges}px;
  padding-right: ${Metrics.margins.defaultMarginToEdges}px;
  border-left: ${borderThickness}px solid #fff;
  border-right: ${borderThickness}px solid #fff;
  ${({ borderedBefore }) => borderedBefore && `border-left-color: ${Colors.black}`};
  ${({ borderedAfter }) => borderedAfter && `border-right-color: ${Colors.black}`};
  background-color: ${Colors.background};

  @media screen and (max-width: ${breakPointMax}px) {
    border-left: none;
    border-right: none;
    border-top: ${borderThickness}px solid ${({ mobileBackgroundColor }) => mobileBackgroundColor || Colors.background};
    border-bottom: ${borderThickness}px solid ${({ mobileBackgroundColor }) => mobileBackgroundColor || Colors.background};
    ${({ borderedBefore }) => borderedBefore && `border-top-color: ${Colors.black}`};
    ${({ borderedAfter, borderedAfterInMobile }) =>
      (borderedAfter || borderedAfterInMobile) && `border-bottom-color: ${Colors.black}`};

    flex: 1;
    height: auto;
    width: 100%;
    padding: ${Metrics.margins.defaultMarginToEdges}px;
    background-color: ${({ mobileBackgroundColor }) => mobileBackgroundColor || Colors.background};
    transition: background-color 100ms ease-out;
  }
`;

export const AdvanceButton = styled.div`
  position: absolute;
  right: ${Metrics.margins.defaultMarginToEdges}px;
  bottom: 0;
  width: 76px;
  height: ${advanceButtonHeight}px;
  cursor: pointer;

  svg {
    width: 76;
    height: 100%;
    fill: ${Colors.brightGreen};
  }

  @media screen and (max-width: ${breakPointMax}px) {
    position: relative;
    right: unset;
    bottom: unset;
    width: 100%;
    border-top: ${borderThickness}px solid ${Colors.black};
    background-color: ${Colors.brightGreen};

    svg {
      width: 100%;
    }
  }
`;
