import React from 'react';
import PropTypes from 'prop-types';

import { Colors } from '../themes';

const ArrowWithBackground = ({ width, height }) => (
  <svg
    version="1.1"
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    style={{ enableBackground: `0 0 ${width} ${height}` }}
  >
    <g>
      <g>
        <rect style={{ fill: Colors.brightGreen }} width="100%" height="100%" />
      </g>
      <g>
        <rect x={width / 4.32} y={height / 2.03} style={{ fill: '#1D1E1C' }} width={width / 2} height={height / 16.5} />
      </g>
      <g>
        <polygon style={{ fill: '#1D1E1C' }} points="40.5,43.8 38.3,41.7 53.8,26.2 37.3,9.8 39.5,7.7 58,26.2" />
      </g>
    </g>
  </svg>
);

ArrowWithBackground.defaultProps = { width: '76', height: '50' };

ArrowWithBackground.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default ArrowWithBackground;
