import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import TwoColumnPage from '../../components/TwoColumnPage';
import { TextContentContainer, AboutIntro, Paragraph, Link } from './styles';

const About = ({ location }) => (
  <TwoColumnPage currentPathName={location.pathname}>
    <TextContentContainer>
      <AboutIntro>
        <Paragraph>
          The fittest one stop assessment technology – dafür steht EECY. Fittest meint die höchstmögliche Anpassbarkeit an
          spezifische Bedürfnisse, One Stop unterstreicht die Unterstützung sämtlicher Anwendungszwecke und Assessment steht für
          die Überprüfung von Wissen, Fähigkeiten und Haltungen zu einem bestimmten Zeitpunkt.
        </Paragraph>
        <Paragraph>
          Digitale Lernplattformen sind oft zu komplex, gehen nicht auf die spezifischen Bedürfnisse von Lernprozessen ein oder
          decken nur einen bestimmten Aspekt des Lernens ab. Dies sind Gründe dafür, dass sich digitales Lernen nur langsam im
          Alltag etabliert und wertvolle Inhalte oft nur schwer zugänglich sind.
        </Paragraph>
        <Paragraph>
          Die von{' '}
          <Link href="https://medignition.com/" target="_blank">
            medignition
          </Link>{' '}
          geschaffene Plattformtechnologie besteht aus kleinsten Bausteinen, die sich beliebig kombinieren lassen. EECY deckt vom
          Lernen via Kurzvideos bis zur sicheren Prüfung alle Aspekte des Lernens ab. Die Plattform lässt sich flexibel auf die
          individuellen Bedürfnisse einer Institution anpassen. Mit EECY lassen sich sowohl Mobile Apps wie auch Webapplikationen
          realisieren.
        </Paragraph>
      </AboutIntro>
    </TextContentContainer>
  </TwoColumnPage>
);

About.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
};

export default withRouter(About);
