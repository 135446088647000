import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Metrics, Colors, Fonts } from '../../themes/index';

const spaceBetweenLinks = 40;
const linkMargin = spaceBetweenLinks / 2;
const linkPadding = linkMargin;

const spaceBetweenLinksMobile = 2;
const linkMarginMobile = spaceBetweenLinksMobile / 2;
const linkPaddingMobile = linkMarginMobile;

export const NavbarContainer = styled.div`
  margin-bottom: ${Metrics.components.navbarReservedSpace - Metrics.components.navbarLinkHeight}px;
  @media screen and (max-width: ${Metrics.grid.phoneMax}px) {
    margin-bottom: 5vh;
  }
`;

export const LinkContainer = styled.div`
  display: inline-block;
  height: ${Metrics.components.navbarLinkHeight}px;
  margin-left: ${({ first }) => (first ? 0 : linkMargin)}px;
  margin-right: ${({ last }) => (last ? 0 : linkMargin)}px;
  padding: 0px ${linkPadding}px;
  background-color: ${({ selected }) => (selected ? Colors.brightGreen : Colors.background)};
  border-left: 2px solid ${Colors.black};
  border-right: 2px solid ${Colors.black};

  @media screen and (max-width: ${Metrics.grid.phoneMax}px) {
    height: ${Metrics.components.navbarLinkHeightMobile}px;
    margin-left: ${({ first }) => (first ? 0 : linkMarginMobile)}vw;
    margin-right: ${({ last }) => (last ? 0 : linkMarginMobile)}vw;
    padding: 0px ${linkPaddingMobile}vw;
  }
`;

export const NavbarLink = styled(Link)`
  text-decoration: none;
  color: ${Colors.components.defaultFontColor};
  font-size: ${Fonts.components.navbarLink}rem;

  &:hover {
    cursor: pointer;
    text-decoration: none;
    color: ${Colors.components.defaultFontColor};
  }
`;

export const DeactivatedNavbarLink = styled.a`
  text-decoration: none;
  color: ${Colors.components.defaultFontColor};
  font-size: ${Fonts.components.navbarLink}rem;

  &:hover {
    cursor: pointer;
    text-decoration: none;
    color: ${Colors.components.defaultFontColor};
  }
`;

export const TwoPartNavbar = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const NavbarGroup = styled.div``;
