import styled from 'styled-components';
import { Colors, Metrics } from '../../themes';

export const TextContentContainer = styled.div`
  max-width: ${Metrics.components.textContentMaxWidth}px;
`;

export const AboutIntro = styled.div`
  margin-bottom: 60px;
`;

export const Paragraph = styled.p`
  margin-bottom: 5px;
`;

export const Link = styled.a`
  background-color: ${Colors.brightGreen};
  padding: 2px 5px;
  color: black;

  &:hover {
    cursor: pointer;
    text-decoration: none;
    color: black;
  }
`;
