const colors = {
  black: '#1E1E1C',
  background: '#FFF',
  brightGreen: '#F5FF00',
};

const components = {
  defaultFontColor: colors.black,
};

export default { ...colors, components };
